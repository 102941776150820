import { action, makeObservable, observable } from "mobx";

import { bookAPI } from "../shared/api";
import { createTag, TCreateAppointment } from "../shared/api/book";
import { Maybe } from "../types";
import { TCompany } from "../types/city.type";
import { TService } from "../types/service.type";
import { TUser } from "../types/user.type";
import { BaseStore } from "./lib/baseStore.type";

export const mockService: TService = {
  id: "111", // need change
  id_kto: "111", // need change
  category_id: 0,
  discount: 0,
  price_max: 1000,
  price_min: 1000,
  title: "UNKNOWN",
  prepaid: "forbidenn",
};


//! current path: event2.missis.laser.ru
const currentTagDiod = "Зона600декабрь22R4";
const currentTagAlex = "Зона890декабрь22R4";

class RegisterStore implements BaseStore {
  @observable user: Maybe<TUser> = null;
  @observable selectedCompany: Maybe<TCompany> = null;
  @observable selectedLaser: Maybe<"diod" | "alex"> = "diod";
  @observable selectedService: TService = mockService;
  @observable isLoading: boolean = false;
  @observable chargeLink: string = "";

  constructor() {
    makeObservable(this);
  }

  @action
  setComapany(company: TCompany) {
    this.selectedCompany = company;
  }

  @action
  setLaser(laser: "diod" | "alex") {
    this.selectedLaser = laser;
  }

  @action
  setService(service: TService) {
    this.selectedService = service;
  }

  @action
  setUser(user: TUser) {
    this.user = user;
  }

  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  resetStore() {
    this.selectedCompany = null;
    this.selectedService = mockService;
  }

  @action
  async createAppointment({ date, email, name, phone, companyId, comment }: TCreateAppointment) {
    const tag = async (record_id: number) => {
      await createTag({
        phone,
        clinic_id: companyId?.toString(),
        record_id: record_id.toString(),
        tag: this.selectedLaser === "diod" ? currentTagDiod : currentTagAlex,
        title: this.selectedService.title,
        laserType: this.selectedLaser,
      });
    };
    try {
      this.setLoading(true);
      const { data } = await bookAPI.createAppointment({
        date,
        email,
        name,
        comment,
        phone,
        companyId,
        selectedServices: [+(companyId === 146058 ? this.selectedService?.id_kto ?? 0 : this.selectedService?.id ?? 0)],
      });
      tag(data.data[0].record_id);
      if (data.data.charge?.url != null) {
        this.chargeLink = data.data.charge?.url;
        /* window.open(data.data.charge?.url) */
        return data.data.charge?.url; // if success
      } else {
        return data.data;
      }
    } catch (error) {
      console.log("createAppointment", error);
      return false;
    } finally {
      this.setLoading(false);
    }
  }
}

export const registerStore = new RegisterStore();
